import { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, NavLink } from "react-router-dom";
import { usePeticion, ESTADOS } from "../herramientas";

export default function Index() {
  const nombreOperacion = "Modificar pedido";

  const [pedidos, setPedidos] = useState([]);
  const [filtroEstado, setFiltroEstado] = useState("todos");
  const [filtroSinPagar, setFiltroSinPagar] = useState("todos")
  const [busqueda, setBusqueda] = useState("");

  const [pagina, setPagina] = useState(1);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const [peticionesTransito, setPeticionesTransito] = useState(0);
  const [filasHoja, setFilasHoja] = useState(10);

  const navigate = useNavigate();
  const peticion = usePeticion();

  function consultar() {
    setPeticionesTransito((prev) => prev + 1);
    let formData = new FormData();
    formData.append("pagina", pagina);
    formData.append("busqueda", busqueda);
    formData.append("filtro_sin_pagar", filtroSinPagar);
    formData.append("filas_hoja", filasHoja);
    formData.append("filtro_estado", filtroEstado)
    peticion("va12/pedidos/", {
      method: "POST",
      body: formData,
    })
      .then((json) => {
        setPedidos(json.pedidos);
        setTotalPaginas(Math.ceil(json.paginas));
        setPeticionesTransito((prev) => prev - 1);
      })
      .catch((e) => {
        alert("No han podido ser cargados los pedidos correctamente: " + e.message);
        setPeticionesTransito((prev) => prev - 1);
      });
  }

  useEffect(() => {
    setPagina(prev=>{
      if (prev === 1) {
        consultar()
      }
      return 1
    })
  }, [busqueda, filtroEstado, filtroSinPagar, filasHoja]);

  useEffect(() => {
    consultar();
  }, [pagina]);

  let contenido;

  if (peticionesTransito > 0) {
    contenido = (
      <tr>
        <td colSpan="7">
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </td>
      </tr>
    );
  } else if (peticionesTransito == 0) {
    contenido = pedidos.map((pedido) => (
      <tr>
        <td class="align-middle text-center">
          <Link to={"modificar/" + pedido.id_pedido} role="button" class="btn btn-warning">
            Modificar
          </Link>
        </td>
        <td class="align-middle text-center">{pedido.id_pedido}</td>
        <td class="align-middle text-center">{pedido.fecha_formateada}</td>
        <td class="align-middle text-center">{pedido.nombre}</td>
        {pedido.sin_pagar > 0 ? <td className="align-middle text-center table-danger">{pedido.sin_pagar}</td> : <td className="align-middle text-center table-success">{pedido.sin_pagar}</td>}
        <td class="align-middle text-center">{pedido.nombre_vendedor}</td>
        <td class="align-middle text-center">{pedido.estado}</td>
      </tr>
    ));
  }

  return (
    <main className="container p-3">
      <h1>{nombreOperacion}</h1>
      <p>En esta operación se muestran los pedidos abiertos y completados.</p>
      <div>
        <div class="row g-3 mb-3">
          <div class="col-sm-6">
            <input
              type="text"
              placeholder="Buscar por número, vendedor, fecha, cliente, teléfono o DNI..."
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              class="form-control"
              id="busqueda"
            />
          </div>
          <div class="col-sm-3">
            <select class="form-select" value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)} aria-label="Default select example">
            <option value="todos">(Todos) Estado...</option>
              <option value="abierto">Abierto</option>
              <option value="completado">Completado</option>
            </select>
          </div>
          <div class="col-sm-3">
            <select class="form-select" value={filtroSinPagar} onChange={(e) => setFiltroSinPagar(e.target.value)} aria-label="Default select example">
              <option value="todos">(Todos) S/P...</option>
              <option value="pagados">Pagos completados</option>
              <option value="sin_pagar">Con artículos sin pagar</option>
            </select>
          </div>
        </div>
        <div className="row g-3 justify-content-end">
          <div className="col-auto">
            <select className="form-select" onChange={(e) => setFilasHoja(e.target.value)}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
          <div className="col-auto">
            <nav>
              <ul className="pagination justify-content-end">
                {pagina > 1 && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => setPagina(1)}>
                      1
                    </button>
                  </li>
                )}
                {pagina > 3 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                {pagina > 2 && (
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => {
                        if (pagina > 1) {
                          setPagina(pagina - 1);
                        }
                      }}
                    >
                      {pagina - 1}
                    </button>
                  </li>
                )}
                <li className="page-item active">
                  <span className="page-link">{pagina}</span>
                </li>
                {pagina < totalPaginas - 1 && (
                  <li className="page-item">
                    <button
                      className="page-link"
                      onClick={() => {
                        setPagina(pagina + 1);
                      }}
                    >
                      {pagina + 1}
                    </button>
                  </li>
                )}
                {pagina < totalPaginas - 2 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                {pagina < totalPaginas && (
                  <li className="page-item">
                    <button className="page-link" onClick={() => setPagina(totalPaginas)}>
                      {totalPaginas}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-striped table-sm table-hover table-bordered">
          <thead>
            <tr>
              <th class="align-middle text-center" scope="col">
                Modificar
              </th>
              <th class="align-middle text-center" scope="col">
                Número
              </th>
              <th class="align-middle text-center" scope="col">
                Fecha
              </th>
              <th class="align-middle text-center" scope="col">
                Cliente
              </th>
              <th class="align-middle text-center" scope="col">
                S/P
              </th>
              <th class="align-middle text-center" scope="col">
                Vendedor
              </th>
              <th class="align-middle text-center" scope="col">
                Estado
              </th>
            </tr>
          </thead>
          <tbody id="tabla">{contenido}</tbody>
        </table>
      </div>
    </main>
  );
}
